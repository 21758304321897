import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import "./landing.css"

const shapes = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },

  { duration: 500, delay: 200 },
  { duration: 500, delay: 200 },

  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },

  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 }
];

const Heading = () => {
  return (
    <header className="header alter2-header section">
      <div className="shapes-container">
        {/* diagonal shapes */}
        {shapes.map((shape, i) => (
          <Fade bottom key={"f" + i}>
            <div
              className="shape shape-animated shape-diagonal low-opacity-shape"
              duration={shape.duration}
              delay={shape.delay}
              key={"d" + i}
            />
          </Fade>
        ))}

        {/* animated shapes */}
        <div className="animation-shape shape-ring animation--clockwise" />
        <div className="animation-shape shape-circle shape-circle-1 animation--anti-clockwise" />
        <div className="animation-shape shape-circle shape-circle-2 animation--clockwise" />
        <div className="animation-shape shape-map-marker animation--rotating-diagonal-anti-clockwise" />
        <div className="animation-shape shape-key animation--clockwise">
          <div className="animation--rotating">
            <i className="pe pe-7s-key pe-2x"></i>
          </div>
        </div>

        <div className="animation-shape shape-lock animation--rotating-diagonal">
          <div className="animation--rotating" />
        </div>
        <div className="animation-shape shape-diamond animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>

        {/* static shapes */}
        <div className="static-shape shape-ring-1" />
        <div className="static-shape shape-ring-2" />

        <div className="static-shape shape-circle shape-circle-1">
          <Fade top right>
            <div />
          </Fade>
        </div>

        <div className="static-shape shape-circle shape-circle-2">
          <Fade top right delay={500}>
            <div />
          </Fade>
        </div>

        <div className="static-shape pattern-dots-1" />
        <div className="static-shape pattern-dots-2" />

        {/*  main shape */}
        <div className="static-shape background-shape-main" />

        {/*  ghost shapes */}
        <div className="static-shape ghost-shape ghost-shape-1" />
      </div>

      <Container>
        <Row>
          <Col md="6">
            <h1 className="display-4 display-md-2 mt-3">
              <span className="bold">Deliverease</span>
            </h1>
            <p className="lead bold text-primary">
              Streamlining Delivery{" "}
              <span className="head-line-2">For the Modern Era</span>
            </p>

            <p className="lead">
              At Deliverease we strive to make the delivery experience 
              as frictionless as possible. By combining seamless access control 
              with rich micro-directions, we help deliver comfort and 
              convenience to your front door.
            </p>

            <nav className="nav my-5">
             <Link 
                to="#subscribe"
                className="btn btn-rounded btn-alternate mr-2 mr-md-5">
                Subscribe for more{" "}
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="ml-2"
                />
              </Link>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfwUdQWqjg3cuml00kYi-8CoJdC7484F2ljY8e1TWv49a2MPg/viewform?usp=sf_link"
                className="btn btn-rounded btn-outline-alternate scrollto"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </nav>
          </Col>

          <Col md="6">
            <div className="iphone front mx-auto">
              <div className="screen shadow-box">
                <img src={require("assets/img/screens/app/AppScreenDelivereaseLanding.jpg")} alt="..." />
              </div>
              <div className="notch" />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
