import React from "react";
import { Container, Row, Col } from "reactstrap";

const renderFeatures = () => {
  let features = [];
  const elements = [
    {
      icon: "pe-7s-map-marker",
      title: "Micro-Directions",
      description:
        "See exactly the front door is, even if GPS can't take you there."
        + " Our rich last-mile directions help you navigate through busy areas and inside of buildings."
    },
    {
      icon: "pe-7s-door-lock",
      title: "Access Control",
      description:
        "Easily and seamlessly unlock doors and gates, making your customers' experience that much better."
        + " Gone is the need for gate codes, key fobs and phone calls."
    },
    {
      icon: "pe-7s-look",
      title: "Security",
      description:
        "Property managers get full visibility into who is accessing the property, and when."
        + " Total control over who can enter a space and how long they can stay."
    }
  ];

  elements.forEach((element, i) => {
    features.push(
      <Col md="4" className="py-4 rounded shadow-hover" key={"c" + i}>
        <i className={`pe ${element.icon} pe-3x mb-3 text-primary`} />
        <h5 className="bold">{element.title}</h5>
        <p>{element.description}</p>
      </Col>
    );
  });

  return features;
};

const Features = () => {
  return (
    <section id="features" className="section features">
      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">How It Works</h2>
          <p className="lead text-muted">
            Our commitment is to making the delivery experience as convenient as possible
            for both tenants as well as our delivery partners,
            while providing a way for properties to further monetize their space.
          </p>
        </div>

        <Row className="gap-y text-center text-md-left">{renderFeatures()}</Row>
      </Container>
    </section>
  );
};

export default Features;
