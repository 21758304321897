import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showToast = message =>
  toast.info(message, {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

export { showToast };
