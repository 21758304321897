import { post } from "./ApiProvider";

const landing = "/landing";

function storeUserEmail(data) {
  const payload = buildRequestPayload(data);
  post(landing, payload);
}

function buildRequestPayload(data) {
  return {
    body: {
      email: data.email,
      zipCode: "",
      role: "",
    },
  };
}

export { storeUserEmail };
