import React, {useState} from "react";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { storeUserEmail } from "api/subcribe.api";
import { showToast } from "utils/toast.utils";

const Subscribe = () => {
  const [input, setInput] = useState({ email: "", zipcode: "", selectedRole: "" });

  const handleInputChange = (event) => {
    event.persist();
    setInput((input) => ({
      ...input,
      [event.target.name]: event.target.value,
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (input.email === "") return;
    storeUserEmail(input);
    showToast("Thanks for signing up!");
    setInput(() => ({
      email: "",
      zipcode: "",
      selectedRole: "",
    }));
  };

  return (
    <section className="section stay-tunned" id="subscribe">
      <Container className="bring-to-front">
        <div className="shadow rounded p-5 bg-contrast overflow-hidden">
          <div className="section-heading text-center">
            <h2 className="heading-line">Stay tuned</h2>
            <p className="lead text-alternate">
              Find out when we launch, and get notified about
              new features and updates
            </p>
          </div>

          <div className="mx-auto position-relative form-wrapper">
            <form
              className="form text-center"
              data-response-message-animation="slide-in-left"
            >
              <div className="form-group input-group">
                <input
                  id="subscribe-email"
                  type="email"
                  name="email"
                  className="form-control form-control-lg text-center"
                  placeholder="Enter your email"
                  value={input.email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <button type="submit" className="btn btn-lg btn-alternate" onClick={submitForm}>
                Register
              </button>
            </form>

            <div className="response-message">
              <FontAwesomeIcon icon={["fas", "envelope"]} className="fa-3x" />
              <p className="font-md m-0">Thanks for signing up</p>
              <p className="response">
                We'll send you an email as soon as we launch!
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Subscribe;
