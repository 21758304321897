import axios from "axios";

const baseUrl = "https://obr04yuygk.execute-api.us-west-2.amazonaws.com/development";
const config = {
  headers: { "Content-Type": "application/json" },
  responseType: "application/json",
};

const post = (path, obj) => {
  const res = axios.post(baseUrl + path, obj, config).then(
    (data) => {
      //console.log(data);
    },
    (err) => {
      console.error("An error occured: ", err);
      Promise.reject(err);
    }
  );
  return res;
};

const get = async (path, params) => {
  let mergedParams = {...config, ...params};
  const res = await axios.get(baseUrl + path, mergedParams);
  return res.data;
};

const postS3 = async (presignedUrl, formData, config) => {
  const res = axios.post(presignedUrl, formData, config).then(
    (data) => {
      console.log(data);
    },
    (err) => {
      console.error("An error occured: ", err);
      Promise.reject(err);
    }
  );
  return res;
}

export { post, get, postS3 };
