import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// routing components
import LandingPage from "views/LandingPage.jsx";

// global template script file
import "./laapp.js";

ReactDOM.render(
  <BrowserRouter>
    <ToastContainer />
    <Switch>
      <Route path="/" exact render={props => <LandingPage {...props} />} />
      <Route path='/app' component={() => { 
        window.location.replace('https://app.getdeliverease.com/app'); 
        return null;
      }}/>
      <Route path='/upload' component={() => { 
        window.location.replace('https://app.getdeliverease.com/upload'); 
        return null;
      }}/>
      <Route path='/directions/:id' component={(data) => {
        const params = data.match.params;
        if (params.id) {
          window.location.replace('https://app.getdeliverease.com/directions/' + params.id); 
        } else {
          window.location.replace('https://app.getdeliverease.com/directions/');
        }
        return null;
      }}/>
      <Route path='/confirmation' component={() => {
        window.location.replace('https://app.getdeliverease.com/confirmation'); 
        return null;
      }}/>
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
